import { Pipe, PipeTransform } from '@angular/core';
import { toPresentationCase } from '../helpers';
@Pipe({
  name: 'presentationcase',
  pure: false,
})
export class PresentationCasePipe implements PipeTransform {
  transform(sentence: string | undefined | null): string {
    return toPresentationCase(sentence);
  }
}
